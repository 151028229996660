<template>
  <div id="page-stats-domain-date">
    <StatsDomainDate />
  </div>
</template>

<script>
import StatsDomainDate from '../../../components/stats/domain/StatsDomainDate'

export default {
  components: {
    StatsDomainDate
  }
}
</script>
